import React, { useRef } from "react";
import CustomHook from "./CustomHook";
function Home() {
  const scrollTab = useRef();
  CustomHook(scrollTab);

  return (
    <section ref={scrollTab} className="Home">
      <div className="content">
        <div className="name">
          BE <br></br>
          <span>THE BEST</span>
        </div>
        <div className="des">
          {/* 30 */}
          Achieve excellence and become the best in programming with our
          comprehensive online courses! Our expertly designed curriculum takes
          you from foundational concepts to advanced techniques, ensuring you
          master every aspect of coding.
        </div>

        <a
          href="https://myclassroom.enlightenlearner.com"
          target="_blank"
          rel="noopener noreferrer"
          className="animation active"
        >
          MyClassRoom
        </a>
      </div>
      <div className="avatar">
        <div className="card">
          <img src="/avatar.jpg" alt="" />
          <div className="info">
            <div>Knowing</div>
            <div>Being</div>
            <div>Doing</div>
            <div>Success</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
