import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPersonCircleQuestion,
  // faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import CustomHook from "./CustomHook";

function Projects() {
  const [listProjects] = useState([
    {
      name: "MyClassRoom",
      des: "We are glad to have you as we wish the best for you and enjoy your learning experience with us.",
      mission: "Contextual E-Learning System",
      language: "HTML5, CSS3, React JS, SockerIO,...",
      images: "/project1.png",
      link: "https://myclassroom.enlightenlearner.com",
    },
    {
      name: "IJISIT ",
      des: "International Journal of Information System & Innovative Technology aims to publish original research results on the implementation of the information systems.",
      mission: "Publishing quality papers",
      language: "HTML5, CSS3, React JS, SockerIO,...",
      images: "/project2.png",
      link: "https://ejournal.enlightenlearner.com",
    },
    // {
    //   name: "Project Real-time chating in website",
    //   des: "Eu voluptate sit do labore consectetur in ad esse qui laborum ad eiusmod. Esse ea velit culpa exercitation anim enim reprehenderit. Fugiat nostrud non dolore aliquip quis in ea amet duis.",
    //   mission: "Back-end Developer, system analysis and design",
    //   language: "HTML5, CSS3, React JS, SockerIO,...",
    //   images: "/project2.PNG",
    // },
  ]);
  const divs = useRef([]);
  const scrollTab = useRef();
  CustomHook(scrollTab, divs);
  return (
    <section className="Products" ref={scrollTab}>
      <div className="title" ref={(el) => el && divs.current.push(el)}>
        Products
      </div>
      <div className="des" ref={(el) => el && divs.current.push(el)}>
        {/* 20 */}
        We provide an envirenment of learning that you can reach your goal of
        learning
      </div>
      <div className="list">
        {listProjects.map((value, key) => (
          <div
            className="item"
            key={key}
            ref={(el) => el && divs.current.push(el)}
          >
            <div className="images">
              <img src={value.images} alt="" />
            </div>
            <div className="content">
              <h3>{value.name}</h3>
              <div className="des">{value.des}</div>
              <div className="mission">
                <div>
                  <FontAwesomeIcon icon={faPersonCircleQuestion} />
                </div>
                <div>
                  <h4>Purpose</h4>
                  <div className="des">{value.mission}</div>
                </div>
              </div>
              <div className="mission">
                {/* <div>
                  <FontAwesomeIcon icon={faEarthAmericas} />
                </div> */}
                <div>
                  <a
                    href={value.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="animation active"
                  >
                    Detail
                  </a>
                  {/* <h4>Link</h4> */}
                  {/* <div className="des">{value.language}</div> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
export default Projects;
